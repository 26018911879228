<template>
    <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block" id="page-register-poli">
      <section class="custom-bg-display">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-xl-12 p-0">
              <div class="box-content">
                <div style="position: absolute;left:0;top:30px;">
                  <h6 class="custom-title-color-white" style="font-size: 16px;font-weight: 600;cursor: pointer;" @click="pindahRegisterLamaBpjs()"><CIcon name="cil-arrow-left" /> Kembali</h6>
                </div>
                <img :src="require(`../../assets/icons/kudus.png`)" style="height: 65px;margin-top: 30px;">
                
                <!-- <h6 style="font-size: 26px;color: #9C4098;margin-top: 15px;font-weight: 600;">Pilih Poli</h6> -->

                <div style="width: 100%;display: flex;justify-content: center;background-color: ;flex-wrap: wrap;height: 94vh;overflow-y: auto;align-content: flex-start;" >
                  <div class="box-menu" v-for="(item, idx) in poli" :key="idx" @click="setPoli(item)">
                    <!-- <img src="../../assets/poli-umum.png" alt=""> -->
                    <h6 style="font-size: 18px;text-align: center;" class="mt-3 mb-0">{{ item.nama_poli }}</h6>
                  </div>

                  <!-- <div class="box-menu" @click="setPoli('POLI GIGI & MULUT')">
                    <img src="../../assets/poli-gigi.png" alt="" >
                    <h6 style="font-size: 18px;" class="mt-3 mb-0">Poli Gigi</h6>
                  </div>

                  <div class="box-menu" @click="setPoli('POLI KIA')">
                    <img src="../../assets/poli-kia.png" alt="" >
                    <h6 style="font-size: 18px;" class="mt-3 mb-0">Poli KIA</h6>
                  </div> -->

                  <!-- <div class="box-menu" @click="setPoli('LABORATORIUM')"> 
                    <img src="../../assets/poli-laborat.png" alt="">
                    <h6 style="font-size: 18px;" class="mt-3 mb-0">Laborat</h6>
                  </div> -->
                </div>
                
              </div>
            </div>
          </div>
        </div>
        
        <div class="myfooter">
          <h6 style="color: #fff;font-size: 12px;text-align: center;" class="m-0">Powered by CV Rapier Technology</h6>
        </div>
      </section>

      <!-- modal register poli -->
      <b-modal id="modal-register-poli" size="md" centered title="Konfirmasi">
        <h6 style="font-size: 32px;font-weight: 600;text-align: center;color: #020202;" class="m-0">{{ pasien.nama_lengkap }}</h6>
        <h6 style="font-size: 16px;color: #020202;text-align: center;" class="mt-3">Lanjutkan Pendaftaran<br/>ke {{ namaPoli }} ?</h6>
        <template #modal-footer="{ ok, cancel }">
          <div style="width: 100%;display: flex;justify-content: center;">
            <b-button variant="success" @click="registerPasien()">
              Ya
            </b-button>

            <b-button variant="default" @click="cancel()">
              Tidak
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- modal tiket poli -->
      <b-modal id="modal-tiket-poli" hide-header size="lg" centered title="Healthical"> 
        <h6 style="font-size: 14px !important;text-align: center;">TIKET KUNJUNGAN</h6>
        <h6 style="font-size: 24px !important;font-weight: 600;text-align: center;">{{ nama_puskesmas }}</h6>
        <h6 style="font-weight: 600;text-align: center;">{{ namaPoli }}</h6>
        <h6 style="font-size: 21px !important;text-align: center;">NO. ANTREAN {{ hasil.nomor_antrian }}</h6>
        <h6 style="font-size: 12px !important;text-align: center;">Tanggal {{ $moment(new Date).format("DD-MM-YYYY HH:mm") }}</h6>

        <b-table-simple class="mt-3">
          <b-tbody>
            <b-tr>
              <b-td style="width: 240px;">No. Pendaftaran</b-td>
              <b-td style="width: 15px;">:</b-td>
              <b-td>{{ hasil.no_pendaftaran }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>NIK</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.nik }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Nama</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.nama_lengkap }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Tanggal Lahir</b-td>
              <b-td>:</b-td>
              <b-td>{{ $moment(pasien.tanggal_lahir).format("DD-MM-YYYY") }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Ayah / KK</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.nama_ayah }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>No. RM</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.no_rm }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Umur</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.umur }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>No. HP</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.no_telp }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Jenis Kelamin</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.jenis_kelamin }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Alamat</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.alamat_sekarang }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Penjamin</b-td>
              <b-td>:</b-td>
              <b-td>BPJS</b-td>
            </b-tr>

            <b-tr>
              <b-td>Nama Penanggung Jawab</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.nama_penjamin }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>No. Telp Penanggung Jawab</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.no_telp_penjamin }}</b-td>
            </b-tr>

            <b-tr>
              <b-td>Hub. Penanggung Jawab</b-td>
              <b-td>:</b-td>
              <b-td>{{ pasien.hubungan_penjamin }}</b-td>
            </b-tr>
            <!-- <b-tr>
              <b-td>Jenis Bantuan</b-td>
              <b-td>:</b-td>
              <b-td>-</b-td>
            </b-tr> -->
          </b-tbody>
        </b-table-simple>

        <!-- <h6 style="text-align: center;" class="mt-3">Rp 0,-</h6> -->
        <template #modal-footer="">
          <div style="width: 100%;display: flex;justify-content: flex-end;">
            <b-button variant="success" @click="cetakPoli('cetak_antrian_poli')">
              Ok
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- CETAK ANTRIAN -->
      <div style="position: relative; display: none;" id="cetak_antrian_poli">
        <section class="content-print paper-58">
          <div class="sheet">
            <div class="w-100">
              <h6 style="text-align: center;"><strong>{{ nama_puskesmas }}</strong></h6>

              <h6 style="font-size: 50px;text-align: center;margin-top: 5mm;"><strong>{{ hasil.no_antrian }}</strong></h6>
              <h6 style="text-align: center;margin-bottom: 5mm;">{{ namaPoli }}</h6>
              <h6 style="text-align: center;font-size: 10pt;margin-top: 5mm;">{{ $moment(new Date).format("DD-MM-YYYY HH:mm") }}</h6>
              <h6 style="text-align: center;font-size: 10pt;">Sisa Antrian : {{ hasil.sisa_antrian }}</h6>
            </div>
          </div>
        </section>
      </div>
    </div>
</template>
<script>
  import axios from "axios";
  import { ipBackend } from "@/ipBackend";
  import moment from "moment"
  // import _ from "lodash"
  moment.locale("ID")
  export default {
    components: {
    //   antrianLoket,
    },
    name: "register_poli",
    data() {
      return {
        loading: false,
        loadingTime: 0,
        maxLoadingTime: 4,
        namaPoli: "",
        poli: [],
        hasil: {
          no_antrian: null
        },
        pasien: null,
        bpjs: null,
        data: {
            tanggal_antrian: moment(new Date()).format("YYYY-MM-DD HH:mm"),
            initial: "",
            keluhan: null,
            ms_poli_id: "",
            jadwal_id: "",
            rm_id: "",
            no_kartu: "",
            kd_tkp: "10",
            kd_provider_peserta: "",
            sistole: 0,
            diastole: 0,
            berat_badan: 0,
            tinggi_badan: 0,
            resp_rate: 0,
            lingkar_perut: 0,
            heart_rate: 0,
            nama_penjamin_pendaftaran: "",
            no_telp_penjamin_pendaftaran: "",
            hubungan_penjamin_pendaftaran: "",
            jenis_peserta_bpjs: "",
            pst_prol: "",
            pst_prb: "",
            is_pbi: null,
            golongan_pasien: "",
            tipe_rawat: "RJ",
            puskesmas_id: "",
            room_id: "",
            jumlah_tagihan_pendaftaran: 0,
            kunjungan_sakit: true,
            rujuk_balik: 0,
        },
        showing: false,
        msg: "",
        variant: "",
        nama_puskesmas: "",
        alamat_puskesmas: "",
      };
    },
    computed:{
      logo() {
      return this.$store.state.logo;
    },
  },
    watch: {
        loading(newValue, oldValue) {
          if (newValue !== oldValue) {
            this.clearLoadingTimeInterval()
  
            if (newValue) {
              this.$_loadingTimeInterval = setInterval(() => {
                this.loadingTime++
              }, 1000)
            }
          }
        },
        loadingTime(newValue, oldValue) {
          if (newValue !== oldValue) {
            
            if (newValue === this.maxLoadingTime) {
              this.loading = false
            }
          }
        }
      },
      
    mounted() {
      let id = this.$store.state.apm_id
      this.nama_puskesmas = localStorage.getItem('nama_puskesmas')
      this.alamat_puskesmas = localStorage.getItem('alamat_puskesmas')
      this.getDatas();
      this.$socket.emit("joinRoom", `${id}`);
    },
    created() {
      this.$_loadingTimeInterval = null
      this.setPasien()
      this.$socket.off("refresh_apm")
        this.$socket.on("refresh_apm", (data) => {
        console.log(data, 'ini refresh_apm');
        
        // this.print(data)
        if(data.message == 'sukses' && data.status == 200){
          this.hasil = data.data
          this.$bvModal.show('modal-tiket-poli')
          this.hasil.no_antrian = `${this.hasil.initial}${this.hasil.nomor_antrian}`
            // this.$router.push({ path: `/display_apm`});
        }else {
          this.$store.commit('set_alert', {showing: true, variant: 'danger', msg: data.message})
        }
      });
      this.$socket.on("error", (data) => {
        if(data.message == 'gagal'){
          this.$store.commit('set_alert', {variant: 'danger', msg: "TERJADI KESALAHAN PADA SERVER", showing: true})
          this.loading = false
        }
      });
    },
    methods: {
        clearLoadingTimeInterval() {
          console.log('itikiwir');
          clearInterval(this.$_loadingTimeInterval)
          this.$_loadingTimeInterval = null
        },
        startLoading() {
          console.log('masuk sini');
          this.loading = true
          this.loadingTime = 0
          
        },
      async print(data){
        let no_antrian = `${data.initial}${data.antrian_no}`
        // let waktu = moment(new Date()).format("dddd, DD MMMM YYYY")
        // let jam = moment(new Date()).format("HH:mm:ss")
        let x = await axios.get(`http://localhost:3000/print?no_antrian=${no_antrian}&tempat=LOKET&sisa_antrian=${data.sisa_antrian}`)
        console.log(x, 'ini xx');
        this.startLoading()
      },
      tutup(){
        let win = window.open("about:blank", "_self")
        win.close()
      },
      setPasien(){
        let vm = this
        vm.pasien = vm.$store.state.data_pasien
        vm.bpjs = vm.$store.state.data_pasien_bpjs
        vm.data.no_rm = vm.pasien.no_rm
        vm.data.nama_penjamin_pendaftaran = vm.pasien.nama_penjamin
        vm.data.no_telp_penjamin_pendaftaran = vm.pasien.no_telp_penjamin
        vm.data.hubungan_penjamin_pendaftaran = vm.pasien.hubungan_penjamin
        vm.data.rm_id = vm.pasien.rm_id
        vm.data.no_kartu = vm.bpjs.noKartu
        vm.data.kd_provider_peserta = vm.bpjs.kdProviderPst.kdProvider
        vm.data.pst_prol = vm.bpjs.pstProl
        vm.data.pst_prb = vm.bpjs.pstPrb
        vm.data.jenis_peserta_bpjs = vm.bpjs.jnsPeserta.nama
        let nama_jenis = `${vm.data.jenis_peserta_bpjs[0]}${vm.data.jenis_peserta_bpjs[1]}${vm.data.jenis_peserta_bpjs[2]}`
        if(nama_jenis == "PBI"){
          vm.data.is_pbi = true
        }else {
          vm.data.is_pbi = false
        }
        // this.jenis_layanan = vm.$store.state.data_static.tkp
        // console.log(vm.jenis_layanan, 'jenis');
      },
      async setPoli(x){
        let vm = this
        vm.data.ms_poli_id = x.ms_poli_id
        vm.data.initial = "A"
        vm.data.kunjungan_sakit = x.poli_sakit
        vm.namaPoli = x.nama_poli
        let jadwal = await vm.$axios.post("/jadwal/list", {
            halaman:0,
            jumlah:100,
            ms_poli_id: vm.data.ms_poli_id,
        })
        if(jadwal.data.data.length){
            vm.data.jadwal_id = jadwal.data.data[0].jadwal_id
            // console.log(vm.data, 'ini data');
            // console.log(vm.pasien, 'pasien');
            vm.$bvModal.show('modal-register-poli')
          }else {
            vm.$store.commit('set_alert', {variant: 'danger', msg: "TERJADI KESALAHAN PADA SERVER", showing: true})
          }
        // vm.$socket.emit("register_pendaftaran_apm_umum", vm.data);
      },
      registerPasien(){
        let vm = this
        vm.data.puskesmas_id = localStorage.getItem('idp')
        vm.data.room_id = localStorage.getItem('idp')
        vm.$socket.emit("register_pendaftaran_apm_bpjs", vm.data);
        console.log(vm.data, 'ini data final');
      },
      async getDatas() {
        let vm = this;
        let poli = await vm.$axios.post("/ms_poli/list", {
          // puskesmas_id: 'a73ffa4d-79ef-4cff-8804-bfae35d391ee'
        });
        console.log(poli, "iki lho hasil e");
        vm.poli = []
        for (let i = 0; i < poli.data.data.length; i++) {
          let x = poli.data.data[i];
          if(x.is_lab == false){
            vm.poli.push(x)
          }
        }
        console.log(vm.poli, 'prikitiws');
        },
      pindahRegisterLamaBpjs(){
        this.$router.push({ path: "/register_lama_bpjs" });
      },
      pindah(){
        this.$router.push({ path: "/display_apm" });
      },
      async cetakPoli(x){
        // if(x == this.data_print){
        //   await this.$htmlToPaper("resep_sederhana");
        // }
        const options = {
          name: '_blank',
          specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
          ],
          styles: [
          '/style-paper-58mm.css',
          ],
          timeout: 1000, // default timeout before the print window appears
          autoClose: true, // if false, the window will not close after printing
          windowTitle: window.document.title, // override the window title
        }
        // console.log(x);
        // await this.$htmlToPaper(x, options);
        await this.$htmlToPaper(x, options);
        setTimeout(() => {
          this.$router.push({ path: "/display_apm" });
        }, 2000);
      },
    },
  };
  </script>
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

  #page-register-poli{
    font-family: 'Inter', sans-serif;
  }

  #page-register-poli .box-content{
    width: 100%;
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  #page-register-poli .box-content .box-menu{
    width: 200px;
    height: 140px;
    background-color: #fff;
    box-shadow: 0px 8px 8px 0px #00000040;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin:10px 5px;
    cursor: pointer;
  }
  #modal-cari td{
  border:1px solid transparent !important;
  vertical-align:top !important;
}

  tr, td{
    border: none !important;
  }
  tr{
    border-bottom: none !important;
  }

  td{
    padding:0 !important;
    font-size: 16px;
    color: #202020;
  }

  #modal-tiket-poli h6{
    font-size: 16px;
    font-weight: 400;
    margin:5px 0;
    color: #202020;
  }

  #page-register-poli .myfooter{
    width: 100%;
    height:6vh;
    background-color: #00000080;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size){
    width:30px !important;
    height:30px !important;
    font-weight: 600;
  }
  </style>
  